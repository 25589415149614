import React from "react";
import { connect } from "react-redux";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { PropTypes } from "prop-types";
import { I18n } from "react-i18nify";
import { Link } from 'react-router-dom';
import store from "store";
import { changeCurrentTheme } from "../../actions/themeActions";
import {
  changeDetailsOrder,
  asyncChangeDetailsOrder
} from "../../actions/detallesCompraActions";
import { filterDetallesOrden } from "../../filters/filterDetalleOrden";
import ConectedPaymentMethodCollapse from "../PaymentMethodCollapse";
import ConnectedBackgroundImage from "../PageBuilder/BackgroundImage";
import FormGenerator from "../PageBuilder/FormGenerator";
import PaymentInfoWillBe from "../PageBuilder/PaymentInfoWillBe";

import { lang } from "../../utils/Translations";

export class CheckoutLanding extends React.Component {
  static propTypes = {
    fecthOrderData: PropTypes.any,
    currentTheme: PropTypes.object,
    detalles: PropTypes.array,
    prefix: PropTypes.string,
    order:  PropTypes.object,
  };

  constructor() {
    super();
    this.formDataChange = this.formDataChange.bind(this);
    this.updateTermsAndConditions = this.updateTermsAndConditions.bind(this);
    this.with_check = ["Redeban", "GlobalPay Redeban", "Pagos Redeban"]
  }

  componentDidMount() {
    const {currentTheme} = this.props;
    this.props.fecthOrderData();
    const active_check = this.with_check.includes(currentTheme.name)
    if(active_check){
      this.setState({ acceptTerms: false });
    } else {
      this.setState({ acceptTerms: true });
    }
  }

  state = {
    formData: {},
    acceptTerms: false,
  };

  formDataChange(fields) {
    this.setState({ formData: fields });
  }

  updateTermsAndConditions({ target }){
    this.setState({ acceptTerms: target.checked });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.detalles !== this.props.detalles) {
      //this.setState({ formData: this.initialValues({ prefix: this.props.prefix, fields: this.props.detalles }) })
    }
  }

  render() {
    const {
      currentTheme,
      detalles,
      order
    } = this.props;

    const {
      id: orderId = null,
      status: orderStatus = null,
      original_amount: originalAmount = null,
      original_currency: originalCurrency = null,
      remaining_amount: remainingAmount = null,
      rate = null
    } = order;

    let payment_will_be = detalles ? (
      <PaymentInfoWillBe
        status={orderStatus}
        amount={this.state.formData["order-amount"]}
        currency={this.state.formData["order-currency"]}
        currentTheme={currentTheme}
        paymentMethods={this.state.formData}
        rate={rate}
        originalCurrency = {originalCurrency}
        originalAmount = {originalAmount}
        remainingAmount={remainingAmount}
      />
    ) : null;

    const { pay_button_up_mobile = false } = currentTheme;
    return (
      <>
      {detalles ?
      (
        <ConnectedBackgroundImage>
        <MDBContainer
          className="card card-noshadow-sm"
          style={{
            backgroundColor: currentTheme.primary_color_contrast || "inherit",
          }}
        >
          <MDBRow>
            <MDBCol lg="7" className="px-4 pb-2">
              {pay_button_up_mobile ? (
                  <MDBCol className="d-block d-lg-none pt-4">
                    {payment_will_be}
                    <ConectedPaymentMethodCollapse
                      orderId={orderId}
                      orderData={this.state.formData}
                      termsAccepted={this.state.acceptTerms}
                    />
                  </MDBCol>
              ) : null}
              <h3
                className="mt-5"
                style={{
                  color: currentTheme.primary_color,
                  fontFamily: currentTheme.font_primary
                }}
              >
                <strong>{I18n.t("checkout-landing-title")}</strong>
              </h3>
              {detalles
                ? (() => {
                    return (
                      <>
                        <FormGenerator
                          prefix={"order"}
                          scheme={{
                            "order-currency": {
                              className: "form-group col-md-2 col-sm-3"
                            },
                            "order-amount": {
                              className: "form-group col-md-4 col-sm-9"
                            }
                          }}
                          subscribeValuesCallback={this.formDataChange}
                          fields={detalles}
                        />
                        {this.with_check.includes(currentTheme.name) && (
                          <div className="form-row">
                            <div className="form-group col pt-1 form-check">
                              <input type="checkbox" className="form-check-input" id="acceptTerms" onChange={this.updateTermsAndConditions} value={this.state.acceptTerms}/>
                              <label className="form-check-label" htmlFor="acceptTerms">
                                {I18n.t("checkout-accept-terms-and-conditions-text")}{" "}
                                <Link
                                  to={{ pathname: "https://cdn.globalpay.com.co/documentacion/Redeban-PoliticadeProtecciondeDatosPersonalesEnero2022GlobalPay.pdf" }}
                                  target="_blank"
                                >
                                {I18n.t("checkout-accept-terms-and-conditions-link")}
                                </Link>
                              </label>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })()
                : null}
              {pay_button_up_mobile ? null : (
                <MDBCol className="d-block d-lg-none pb-4">
                  <div
                    style={{
                      background: currentTheme.side_bar_middle_color,
                      padding: '5%'
                    }}
                  >
                    {payment_will_be}
                  </div>
                  <ConectedPaymentMethodCollapse
                    orderId={orderId}
                    orderData={this.state.formData}
                    termsAccepted={this.state.acceptTerms}
                  />
                </MDBCol>
              )}
            </MDBCol>
            <MDBCol
              lg="5"
              className="d-none d-lg-flex flex-column"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                background: currentTheme.side_bar_color,
              }}
            >
              <div
                style={{
                  background: currentTheme.side_bar_middle_color,
                  padding: '5%'
                }}
              >
                {payment_will_be}
              </div>
              <MDBCol className="py-4 equal-distribution" style={{padding: '0 8%'}}>
                <ConectedPaymentMethodCollapse
                  orderId={orderId}
                  orderData={this.state.formData}
                  termsAccepted={this.state.acceptTerms}
                />
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </ConnectedBackgroundImage>
      ):(
        <MDBCol size="12" className="full-centering">
          <div className="spinner-border preloader-big" style={{
            borderColor:"#bfbfbf",
            borderRightColor: "transparent"
          }} role="status">
          </div>
        </MDBCol>
      )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  detalles: state?.detalles?.data
      ? filterDetallesOrden(state.detalles.data.data_buy)
      : null,
  currentTheme: state.theme,
  order: state?.detalles?.data?.order || {},
});

const getLang = (lang) => {
  let language = lang;
  switch (lang) {
    case "pt":
    case "es":
      language = lang;
      break;
    default:
      language = "en";
  }
  return language;
};

const mapDispatchToProps = (dispatch, props) => ({
  changeCurrentTheme: (name, e) => {
    dispatch(changeCurrentTheme(e));
  },
  fecthOrderData: () => {
    store.set("linktopay", {
      lang: props.match.params.lang || lang[0] || "es"
    });
    const configs = store.get("linktopay");
    I18n.setLocale(getLang(configs.lang));
    dispatch(asyncChangeDetailsOrder(props.match.params.id));
  },
  handleInputChange: (name, e) => {
    const { id, value } = e.target;
    dispatch(changeDetailsOrder(id, value));
  }
});

const conectedCheckoutLanding = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutLanding);

export default conectedCheckoutLanding;
